.JournalCoverPreview {
    &__image {
      margin: 0 auto;
      max-width: 714px;
    }

    &__preview {
      left: auto;
      right:auto;
    }

    &__preview_frame {
      //position: absolute;
      left:auto;
      right: auto;
      opacity:0.5;
      transition: opacity 0.5s ease-in-out;
      width: 400px;//714px;
      height: 286px;
    }
    &__preview_frame:hover {
      opacity: 0.0;
    }

    &__preview_img {
      display: block;
      margin: auto;
  
      
      border: 1px solid darkslategrey;
    }
  }
  
