.ManageSubscriptionSection {
  &__container {
    max-width: 850px;
  }

  &__button {
      margin-right: auto;
      width: 100px;
    //   position: relative;
    //   top: -5em;
  }
}
