.SectionHeader {
  &__header {
    margin-bottom: 2rem;

    // Remove margin if nothing after header
    &:last-child {
      margin-bottom: 0;
    }

    // Added if props.centered is true
    &.is-centered {
      text-align: center;
    }

    .title {
      &.is-tight {
        margin-bottom: 0;
      }
    }

    .subtitle {
      max-width: 700px;
      // So we can have max-width but still
      // have alignment controlled by text-align.
      display: inline-block;
    }
  }
}
