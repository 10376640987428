.CoverChooseSection {
    &__container {
      max-width: 800px;
    }

    &__button {
      margin-left: auto;
      width: 100px;
    }
  }
  