.ContactSection {
  &__container {
    max-width: 850px;
  }

  &__button {
      margin-left: auto;
      width: 100px;
  }
}
